import { PaymentsFiltersRequest } from '@api/admin/adminGeneratedApi';
import { Filter } from '@components/Filter';
import colors from '@styles/colors.module.scss';
import { studentGrades } from '@services/grades';
import { Option } from '@components/Select/types';
import styles from './styles.module.scss';

export const PaymentsFilters = () => (
  <Filter<PaymentsFiltersRequest>
    onChange={() => null}
    trackedFields={[
      {
        type: 'datepicker',
        name: 'confirmationDateFrom',
        props: {
          customLabel: <span className={styles.customLabel}>Дата создания:</span>,
          placeholder: 'Начало',
          textFieldProps: {
            placeholder: 'Начало',
            sx: { width: '160px', background: colors.white },
          },
        },
      },
      {
        type: 'datepicker',
        name: 'confirmationDateTo',
        props: {
          placeholder: 'Конец',
          textFieldProps: { placeholder: 'Конец', sx: { width: '160px', background: colors.white } },
        },
      },
      {
        type: 'select',
        name: 'period',
        props: {
          customLabel: <span className={styles.customLabel}>Сессия:</span>,
          placeholder: 'Сессия',
          sx: { width: '276px', background: colors.white },
          options: studentGrades.map((item): Option => ({ label: item, value: item })),
          multiple: true,
        },
      },
    ]}
  />
);
