import { useMemo, useState } from 'react';
import { useParams } from 'react-router';
import { Box, Divider, Stack, Typography } from '@mui/material';
import { Table } from '@components/Table/Table';
import { useGetHumanWorkPlan } from '@area/student/hooks/useGetHumanWorkPlan';

import { LoadingSpinner } from '@components/LoadingSpinner';
import { Column } from '@components/Table/types';
import { StudentWorkPlanResponseItem } from '@api/admin/adminGeneratedApi';
import { Select } from '@components/Select';
import { Option } from '@components/Select/types';
import { studentGrades } from '@services/grades';
import styles from './styles.module.scss';

const periods: Option[] = [
  {
    label: 'За все время',
    value: '',
  },
  ...studentGrades.map((item): Option => ({ label: item, value: item })),
];

export const AcademicPerformance = () => {
  const { id } = useParams();
  const [selectedPeriod, setSelectedPeriod] = useState<Option>(periods[0]);
  const { data, isLoading, isError } = useGetHumanWorkPlan(id!, selectedPeriod.value);

  const columns: Column<StudentWorkPlanResponseItem>[] = useMemo(
    () => [
      {
        title: 'Мероприятие',
        dataIndex: 'event',
        key: 'event',
        sx: { whiteSpace: 'normal' },
        render: (_value, row) => row.disciplineName,
      },
      {
        title: 'Форма контроля',
        dataIndex: 'type',
        key: 'type',
        sx: { whiteSpace: 'normal' },
        render: (_value, row) => row.type,
      },
      {
        title: 'Сессия',
        dataIndex: 'yearName',
        key: 'yearName',
        width: '102px',
        sx: { whiteSpace: 'normal' },
        render: (_value, row) => `${row.distributionPartLabel} ${row.yearName}`,
      },
      {
        title: 'Оценка в сессию',
        dataIndex: 'markInSession',
        key: 'markInSession',
        render: (_value, row) => `${row.markInSessionString} (${row.markInSession})`,
      },
      {
        title: 'Итоговая оценка',
        dataIndex: 'mark',
        key: 'mark',
        render: (_value, row) => `${row.markString} (${row.mark})`,
      },
      {
        title: 'Дата сдачи',
        dataIndex: 'performDate',
        key: 'performDate',
        render: (_value, row) => row.performDate,
      },
      {
        title: 'Преподаватель',
        dataIndex: 'teacher',
        key: 'teacher',
        sx: { whiteSpace: 'normal' },
        render: (_value, row) => row.teacher,
      },
    ],
    [],
  );

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (isError) {
    return (
      <Box>
        <Typography>Ошибка загрузки данных</Typography>
      </Box>
    );
  }

  if (!data) {
    return (
      <Box>
        <Typography>Нет данных</Typography>
      </Box>
    );
  }

  return (
    <>
      <Box mb="25px" maxWidth="300px">
        <Select value={selectedPeriod} onChange={(option) => setSelectedPeriod(option as Option)} options={periods} />
      </Box>
      <Stack flex={1} divider={<Divider className={styles.divider} />}>
        {data?.length === 0 ? (
          <Box>
            <Typography>Нет данных</Typography>
          </Box>
        ) : (
          <Table<StudentWorkPlanResponseItem> columns={columns} data={data} />
        )}
      </Stack>
    </>
  );
};
