import { useEffect, useState } from 'react';
import { Grid, Button, Box, Typography, LinearProgress, Stack } from '@mui/material';
import { FormProvider, useForm, DefaultValues, SubmitHandler } from 'react-hook-form';
import indents from '@styles/indents.module.scss';
import { FormField } from '@components/FormField';
import { Icon } from '@components/Icon';
import { useYupValidationResolver } from '@hooks/useYupValidationResolver';
import { Modal } from '@components/Modal';
import { Info } from '@area/notificationsPage/components/Info';
import { NotificationsResponse } from '@api/admin/adminGeneratedApi';
import { EditTargetsModal } from '@area/notificationsPage/components/EditTargetsModal';
import { PushFormData, validationSchema } from '../validation/validationSchemaPush';
import styles from './style.module.scss';

interface FormProps {
  defaultValues: DefaultValues<PushFormData>;
  onSubmit: SubmitHandler<PushFormData>;
  isNew?: boolean;
  readonly?: boolean;
  isLoading: boolean;
  notification?: NotificationsResponse;
  onSend?: () => void;
  onDelete?: () => void;
}

export const EditPushForm = ({
  defaultValues,
  onSubmit,
  readonly = false,
  isLoading,
  notification,
  onSend,
  onDelete,
}: FormProps) => {
  const [sendModalOpen, setSendModalOpen] = useState(false);
  const [editTargetsModalOpen, setEditTargetsModalOpen] = useState(false);
  const resolver = useYupValidationResolver<PushFormData>(validationSchema);

  const form = useForm<PushFormData>({
    defaultValues: defaultValues as unknown as PushFormData,
    resolver,
  });
  const targets = form.watch('targets');
  const image = form.watch('image');
  const handleSubmit = form.handleSubmit(onSubmit);

  const toggleSendModal = () => setSendModalOpen(!sendModalOpen);
  const onSendLocal = async () => {
    if (Object.keys(form.formState.dirtyFields).length) {
      await handleSubmit();
    }

    onSend?.();
  };

  useEffect(() => {
    if (form.formState.isSubmitting) return;
    form.reset(defaultValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValues]);

  return (
    <>
      <Grid item container flexDirection="column" gap={indents.l} className={styles.formWrapper}>
        {/*<Grid item className={styles.logBtnWrapper}>*/}
        {/*  {!isNew && (*/}
        {/*    <Button variant="outlined" className={styles.logBtn} onClick={toggleSendModal}>*/}
        {/*      Лог рассылки*/}
        {/*    </Button>*/}
        {/*  )}*/}
        {/*</Grid>*/}
        <FormProvider {...form}>
          {/*<Grid item>*/}
          {/*  <FormField*/}
          {/*    name="date"*/}
          {/*    type="dateTimePicker"*/}
          {/*    title="Дата отправки"*/}
          {/*    sx={{ width: 200 }}*/}
          {/*    disabled={readonly}*/}
          {/*  />*/}
          {/*</Grid>*/}
          <Grid item>
            <FormField name="title" title="Заголовок письма" fullWidth disabled={readonly} />
          </Grid>
          <Grid item>
            <Info notification={notification} />
          </Grid>
          <Grid item>
            <FormField name="text" title="Текст" multiline minRows={3} fullWidth disabled={readonly} />
          </Grid>
          <Grid item>
            <FormField name="link" title="Ссылка" fullWidth disabled={readonly} />
          </Grid>
          <Grid item sx={{ width: 304 }}>
            {/*<FormField name="img" title="Иллюстрация" type="image" canUseLink />*/}
            <Stack gap="12px">
              <FormField
                name="image"
                title="Ссылка на изображение"
                placeholder="https://"
                fullWidth
                disabled={readonly}
              />
              <Box className={styles.imagePreviewWrapper}>
                {image ? (
                  <img src={image} alt="" className={styles.imagePreview} />
                ) : (
                  <Typography variant="body2">Предпросмотр</Typography>
                )}
              </Box>
            </Stack>
          </Grid>
          <Grid item flex={1}>
            <Typography variant="h2" marginBottom="12px">
              Всего адресов: {targets ? targets.split(',').length : 0}
            </Typography>
            <Button variant="contained" onClick={() => setEditTargetsModalOpen(true)}>
              {readonly ? 'Просмотр' : 'Изменить'}
            </Button>
          </Grid>
        </FormProvider>
        {isLoading && <LinearProgress className={styles.loadingIndicator} />}
      </Grid>
      <Box className={styles.submitBtnWrapper}>
        <Button
          variant="contained"
          className={styles.submitBtn}
          disabled={readonly || form.formState.isSubmitting}
          startIcon={<Icon name="logoIcon" className={styles.submitBtnIcon} />}
          onClick={handleSubmit}>
          Сохранить
        </Button>
        {onSend && (
          <Button
            disabled={form.formState.isSubmitting}
            variant="contained"
            className={styles.sendBtn}
            startIcon={<Icon name="send" className={styles.submitBtnIcon} />}
            onClick={onSendLocal}>
            Отправить
          </Button>
        )}
        {onDelete && (
          <Button
            disabled={form.formState.isSubmitting}
            variant="outlined"
            className={styles.delBtn}
            startIcon={<Icon name="delete" className={styles.submitDelIcon} />}
            onClick={onDelete}>
            Удалить
          </Button>
        )}
      </Box>
      <EditTargetsModal
        type="pushapp"
        readonly={readonly}
        targets={targets}
        open={editTargetsModalOpen}
        onClose={() => setEditTargetsModalOpen(false)}
        onSave={(value) => form.setValue('targets', value)}
      />
      <Modal
        open={sendModalOpen}
        title="Отправка сообщения"
        maxWidth="sm"
        onClose={toggleSendModal}
        actions={
          <Button variant="contained" onClick={toggleSendModal} className={styles.closeModalBtn}>
            Закрыть
          </Button>
        }>
        <Grid container gap={indents.s}>
          <Grid item xs={12}>
            <Typography variant="body2">Собираем HTML письма.</Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="body2">Письмо собралось.</Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="body2">Создаем сообщение.</Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography>Рассылка запускается....</Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography>Рассылка запущена.</Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography>Отправлено: 1 пользователю..</Typography>
          </Grid>
        </Grid>
      </Modal>
    </>
  );
};
