import { Option } from '@components/Select/types';
import { ApplicationType, HumansFilterRequest } from '@api/admin/adminGeneratedApi';
import { PaymentType } from '@area/payments/types';

export enum StatusSelectValue {
  ACTIVE = 'active',
  NOT_ACTIVE = 'notActive',
  ACTIVE_STUDENT = 'activeStudent',
  ACTIVE_NOT_STUDENT = 'activeNotStudent',
}

export type FilterFormValues = Partial<
  Omit<
    HumansFilterRequest,
    | 'isActive'
    | 'isStudent'
    | 'unconfirmedByEduOrgs'
    | 'eduOrgs'
    | 'educationalPrograms'
    | 'courseNumber'
    | 'applicationType'
    | 'studentStatus'
  > & {
    status: Option<StatusSelectValue>;
    unconfirmedByEduOrgs: Array<Option>;
    eduOrgs: Array<Option>;
    studentStatus: Array<Option>;
    educationalPrograms: Array<Option>;
    courseNumber: Array<Option<number>>;
    applicationType: Option<ApplicationType>;
  }
>;

export const DEFAULT_STUDENTS_SIZE = 50;

export const studentsFilterOptions = [
  { label: 'Статус', value: 'status' },
  { label: 'Статус студента', value: 'studentStatus' },
  { label: 'ВУЗ', value: 'eduOrgs' },
  { label: 'Неподтвержденный ВУЗ', value: 'unconfirmedByEduOrgs' },
  { label: 'Курс', value: 'courseNumber' },
  { label: 'Специальность', value: 'educationalPrograms' },
  { label: 'Программа', value: 'applicationType' },
  { label: 'Зарегистрирован ДО', value: 'registrationDateTo' },
  { label: 'Зарегистрирован ПОСЛЕ', value: 'registrationDateFrom' },
];

export const paymentOptions = [
  { label: 'Выплата дополнительной стипендии', value: PaymentType.extraGrant },
  { label: 'Компенсация образовательного кредита', value: PaymentType.creditGrant },
];
