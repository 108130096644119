import { Filter } from '@components/Filter';
import { FC } from 'react';
import colors from '@styles/colors.module.scss';
import { Icon } from '@components/Icon';
import { useGetEduOrgs } from '@area/payments/hooks/useGetEduOrgs';
import { LoadingSpinner } from '@components/LoadingSpinner';
import { statusOptions, typeOptions } from '../defaultValues';
import { ApplicationsFilterValues } from './types';

interface Props {
  onChange: (values: any) => void;
}

export const ApplicationsFilter: FC<Props> = ({ onChange }) => {
  const { data: eduOrgs, isLoading } = useGetEduOrgs();
  const eduOrgsOptions = eduOrgs?.map(({ id, nameShort }) => ({ label: nameShort, value: id }));

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <Filter<ApplicationsFilterValues>
      onChange={onChange}
      searchField={{
        type: 'text',
        name: 'search',
        props: {
          placeholder: 'Поиск',
          sx: { minWidth: '340px', background: colors.white },
        },
        stretchable: true,
      }}
      trackedFields={[
        {
          type: 'select',
          name: 'status',
          props: {
            placeholder: 'Статус',
            icon: <Icon name="filter" />,
            options: statusOptions,
            sx: { width: '240px', background: colors.white },
            multiple: true,
          },
        },
        {
          type: 'datepicker',
          name: 'createdAt',
          props: {
            placeholder: 'Дата подачи',
            textFieldProps: {
              placeholder: 'Дата подачи',
              sx: { width: '160px', background: colors.white },
            },
          },
        },
        {
          type: 'select',
          name: 'type',
          props: {
            placeholder: 'Тип заявки',
            sx: { width: '375px', background: colors.white },
            icon: <Icon name="rubric" />,
            options: typeOptions,
          },
        },
        {
          type: 'select',
          name: 'eduOrgs',
          props: {
            multiple: true,
            options: eduOrgsOptions ?? [],
            placeholder: 'ВУЗ',
            sx: { width: '280px', background: colors.white },
          },
        },
      ]}
    />
  );
};
