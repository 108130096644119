import * as yup from 'yup';

export type PushFormData = {
  title: string;
  text: string;
  link: string;
  targets: string;
  image?: string;
};

export const validationSchema = yup.object<PushFormData>({
  title: yup
    .string()
    .required('Обязательное поле.')
    .min(5, 'Минимальное количество символов 5')
    .max(100, 'Минимальное количество символов 100'),
  text: yup
    .string()
    .required('Обязательное поле.')
    .min(5, 'Минимальное количество символов 5')
    .max(250, 'Минимальное количество символов 250'),
  link: yup.string(),
  targets: yup.string(),
});
